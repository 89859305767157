import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Chip,
  ThemeProvider,
  createTheme,
  CssBaseline,
  Snackbar,
  Alert
} from "@mui/material";
import {
  Refresh as RefreshIcon,
  Add as AddIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faGitlab,
  faBitbucket,
  faMicrosoft,
} from "@fortawesome/free-brands-svg-icons";
import { faCodeBranch } from "@fortawesome/free-solid-svg-icons";
import { API_URL } from "../globals";
import { supabase } from "../supabaseClient";
import axios from "axios";
import AddRepoModal from "./AddRepoModal";
import AddStudioModal from "./AddStudioModal";
import { useNavigate } from "react-router-dom";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const getProviderIcon = (provider) => {
  switch (provider.toLowerCase()) {
    case "github":
      return <FontAwesomeIcon icon={faGithub} />;
    case "gitlab":
      return <FontAwesomeIcon icon={faGitlab} />;
    case "bitbucket":
      return <FontAwesomeIcon icon={faBitbucket} />;
    case "azure_devops":
      return <FontAwesomeIcon icon={faMicrosoft} />;
    default:
      return null;
  }
};

const DataSourcesPage = () => {
  const [repos, setRepos] = useState([]);
  const [studios, setStudios] = useState([]);
  const [openRepoModal, setOpenRepoModal] = useState(false);
  const [studioModalOpen, setOpenStudioModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [snanckbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();

  const handleRefreshRepo = async (repo) => {
    setIsLoading(true);
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    };

    const repoData = {
      git_provider: repo.git_provider,
      repo_name: repo.repo_name,
      branch: repo.branch,
      gitlab_token: repo.gitlab_access_token, // Include only if necessary and available
      gitlab_domain: repo.gitlab_domain, // Include only if necessary and available
    };

    try {
      const response = await axios.post(`${API_URL}/repo`, repoData, {
        headers,
      });
      console.log(`Repository re-added: ${repo.repo_name}`);
      await getAvailableRepos(); // Refresh the list of repositories
      setIsLoading(false);
    } catch (error) {
      console.error(`Error re-adding repository: ${repo.repo_name}`, error);
    }
  };

  async function getAvailableRepos() {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/repos`, {
        headers: headers,
      });

      if (response.data) {
        console.log(response);
        setRepos(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function getAvailableStudios() {
    const authToken = (await supabase.auth.getSession()).data.session
      .access_token;
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    try {
      const response = await axios.get(`${API_URL}/studio/seeds`, {
        headers: headers,
      });

      if (response.data) {
        console.log(response);
        // Sort studios in reverse chronological order
        const sortedStudios = response.data.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });

        setStudios(sortedStudios);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAvailableRepos();
    getAvailableStudios();
  }, []);

  const handleOpenRepoModal = () => {
    setOpenRepoModal(true);
  };

  const handleOpenStudioModal = () => {
    setOpenStudioModal(true);
  };

  const handleCloseRepoModal = () => {
    getAvailableRepos();
    setOpenRepoModal(false);
  };

  const handleCloseStudioModal = () => {
    setOpenStudioModal(false);
  };

  const handleStudioSuccess = () => {
    setSnackbarOpen(true);
    getAvailableStudios();
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box sx={{ minHeight: "100vh", p: 4 }}>
        <Typography variant="h2" component="h1" gutterBottom>
          Data Sources
        </Typography>

        {/* <Box sx={{ mb: 6 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h4" component="h2">
              Repositories (Legacy)
            </Typography>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleOpenRepoModal}
              sx={{
                backgroundColor: "white",
                color: "black",
                "&:hover": {
                  backgroundColor: "teal",
                  color: "white",
                },
              }}
            >
              Add Repository
            </Button>
          </Box>
          <Grid container spacing={3}>
            {repos.map((repo) => (
              <Grid item xs={12} sm={6} md={4} key={repo.id}>
                <Card>
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        mb: 1,
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {getProviderIcon(repo.git_provider)}
                        <Typography
                          variant="h6"
                          component="h3"
                          sx={{ ml: 1, fontSize: "10pt" }}
                        >
                          {repo.repo_name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <FontAwesomeIcon icon={faCodeBranch} fontSize="small" />
                      <Typography variant="body2" sx={{ ml: 1 }}>
                        {repo.branch}
                      </Typography>
                    </Box>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mb: 2 }}
                    >
                      {repo.created_at.split(" ").slice(0, 4).join(" ")}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        mb: 0,
                      }}
                    >
                      <Chip
                        label={repo.documentation_state}
                        color={
                          repo.documentation_state === "COMPLETE"
                            ? "success"
                            : "warning"
                        }
                        size="small"
                      />
                      <IconButton
                        onClick={() => handleRefreshRepo(repo)}
                        disabled={
                          repo.documentation_state === "PROGRESS" || isLoading
                        }
                        sx={{ p: 0 }}
                      >
                        <RefreshIcon />
                      </IconButton>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box> */}

        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h4" component="h2">
              Studios
            </Typography>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleOpenStudioModal}
              sx={{
                backgroundColor: "white",
                color: "black",
                "&:hover": {
                  backgroundColor: "teal",
                  color: "white",
                },
              }}
            >
              Create Studio
            </Button>
          </Box>
          <Grid container spacing={3}>
            {studios.map((studio) => (
              <Grid item xs={12} key={studio.id}>
                <Card>
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          component="p"
                          sx={{ ml: 1, fontSize: "10pt" }}
                        >
                          {studio.display_name}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Chip
                          sx={{
                            mr: 1,
                            backgroundColor:
                              studio.status === "READY" ? "green" :
                              studio.status === "BOOTING" ? "orange" :
                              studio.status === "INDEXING" ? "orange" :
                              studio.status === "FAILED" ? "red" :
                              "default", // Fallback color
                            color: "white",
                          }}
                          label={studio.status}
                          size="small"
                        />
                        <IconButton
                          onClick={() => navigate(`/studio/${studio.id}`)}
                          disabled={studio.status !== "READY" || isLoading}
                          sx={{ p: 0 }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      <Snackbar
        open={snanckbarOpen}
        //anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          autoHideDuration={6000}
          severity="success"
          sx={{ width: "100%" }}
        >
          Successfuly created a new studio. Please wait until it's ready to set up. It may take a couple of minutes...
        </Alert>
      </Snackbar>
      <AddRepoModal open={openRepoModal} onClose={handleCloseRepoModal} />
      <AddStudioModal open={studioModalOpen} onClose={handleCloseStudioModal} onSuccess={handleStudioSuccess} />
    </ThemeProvider>
  );
};

export default DataSourcesPage;